import React, { useState } from "react"

const initial = {
  context: {
    finalPrice: 0,
    vehicleInfo: {},
    mainPhotoUrl: ""
  },
  setContext: () => { }
}

const FinanceCalculatorContext = React.createContext(initial)

function FinanceCalculatorProvider(props) {
  const [context, setContext] = useState(initial.context)

  return (
    <FinanceCalculatorContext.Provider value={[ context, setContext ]}>
      { props.children }
    </FinanceCalculatorContext.Provider>
  )
}

export { FinanceCalculatorContext, FinanceCalculatorProvider }